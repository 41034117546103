import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
`

function BookingCheckoutSuccess() {
  return (
    <Container>
        <BsFillCheckCircleFill size={20} color='green' />
        <p>Booking Checked Out Successfully. Till Next time :&#41;</p>
    </Container>
  )
}

export default BookingCheckoutSuccess