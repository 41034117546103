import { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { GiTowTruck } from 'react-icons/gi'
import { BiUserPlus } from 'react-icons/bi' 
import { BsFillCheckSquareFill } from 'react-icons/bs'
import useCollapse from 'react-collapsed'

import { towerCreateSuccess } from '../../tower/tower.crud.slice'
import { updateTowerReset } from '../../tower/tower.update.slice'
import { createTowerReset } from '../../tower/tower.create.slice'
import { deleteTowerReset } from '../../tower/tower.delete.slice'
import { towersUIUpdate } from '../../tower/towers.list.slice'
import Tower from '../../tower/tower'
import { Loading } from '../../loader/loader'
import { fetchTowers } from '../../tower/towers.list.slice'
import { InputFieldReq } from '../../field/field'
import { FormButton } from '../../button/Button'
import useGetFormValuesAndMethods from '../../../utils/form-hook'
import { Uploading } from '../../loader/loader'
import { createTowerProfile } from '../../tower/tower.create.slice'
import { InteractiveModal } from '../../modal/Modal'
import { AiOutlineReload } from 'react-icons/ai'
import { fetchServicesSubscription } from '../../subscriptions/services.slice'

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${props => props.background || "transparent" };
  color: ${props => props.color || "black" };
  padding: 5px 10px;
  border-radius: 7px;
  border: ${props => props.border || "none" };
  cursor: pointer;
  margin-right: 10px;
  width: ${props => props.width || "auto" };
  font-weight: bolder;
   ${(props) => props.disabled && css`
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
    `
  }
  `

const Wrapper = styled.div`
display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const TowerUpdateSuccess = ({text}) => {

return (
    <div className="tower__success">
        <BsFillCheckSquareFill color='green' />
        <p className='tower__success-text'>{text}</p>
    </div>
)
}

export const Error = ({error}) => {

    return (
        <div className='booking__notif'>
            <p>{error}</p>
        </div>
    )
}

export const NoItems = ({text}) => {

    return (
        <div className='booking__notif'>
            <p>{text}</p>
        </div>
    )
}

const Towers = ({ companyID }) => {
    const { profile: { companyName, subscriptionTier, currentSubscriptionTier } } = useSelector(state => state.dashboardProfile)
    const { creating, created, createTowerProfileError } = useSelector(state => state.createTowerProfile)
    const { towerDelete } = useSelector(state => state.towerDelete)
    const { towers, loading, towersError } = useSelector(state => state.towers)
    const { create: { tower: createTower, isTowerCreated }} = useSelector(state => state.towerCRUD)
    const { subscription, subscriptionError} = useSelector(state => state.servicesSubscription)
    const [isAddTowerExpanded, setIsAddTowerExpanded] = useState(false)
    const [vehicleRegistration, setVehicleRegistration] = useState('')
    const [isTierLimitReached, setIsTierLimitReached] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse(isAddTowerExpanded)
    const [{formData},formMethods,formErrors] = useGetFormValuesAndMethods()
    const {fullName,email:formEmail} = formData
    const { user: { email:companyEmail, sub }, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()
    const accessToken = useRef(null)

    useEffect(() => {
        let isCancelled = false
        const getTowers = async () => {
            // dispatch(fetchServicesSubscription('Roadside Assist'))
            try {
                accessToken.current = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                  })
                if (!isCancelled) {
                    dispatch(fetchTowers(companyEmail,sub,accessToken.current))
                }
            } catch (error) {
               alert('Could not fetch towers :(') 
            }
        }
        getTowers()
        
        return () => {
            isCancelled = true
            dispatch(updateTowerReset())
            dispatch(createTowerReset())
            dispatch(deleteTowerReset())
        }
    },[])

    // useEffect(() => {
    //     if (subscription) {
    //         let key = currentSubscriptionTier ? currentSubscriptionTier.toLowerCase() :  subscriptionTier.toLowerCase()
    //         if (towers.length >= subscription.plans[key].max) {
    //             setIsTierLimitReached(true)
    //         } else {
    //             setIsTierLimitReached(false)
    //         }
    //     } else if (subscriptionError) {
    //         alert('Error fetching Subscription Data. Try later.')
    //     }
    // },[towers, subscription])

    useEffect(() => {
        if (created) {
            const towerObj = { 
                fullName, companyID, companyName, companyEmail, email: formEmail, vehicleRegistration,
                requestsCount: 0, requestsAccepted: 0, requestsRejected: 0, requestsIgnored: 0,
                requestsCompleted: 0, rating: 0, ratingFilter: 0, reviewsTotalScore: 0, reviewsCount: 0 }
            setTimeout(() => {
                dispatch(towerCreateSuccess(towerObj))
                dispatch(createTowerReset())
            },2000)
            formMethods.setFormData({...formData, fullName: '', email: ''})
            setVehicleRegistration('')
        }
    },[created])

    useEffect(() => {
        if (towerDelete) {
            refetch()
        }
    },[towerDelete])

    useEffect(() => {
        const updateUIList = () => {
            if (isTowerCreated) {
                dispatch(towersUIUpdate([...towers, createTower]))
            }   
        }

        if (createTower) {
            updateUIList()
        }
    },[createTower])

    const onVehicleRegChange = (e) => {
        const { value } = e.target
        setVehicleRegistration(value.toUpperCase())
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        if (companyEmail === formEmail) {
            return window.alert('Tower cant use Company Email. Choose a different email.')
        }
        try {
            const postData = { fullName, companyID, companyName, companyEmail, email: formEmail, vehicleRegistration,
            requestsCount: 0, requestsAccepted: 0, requestsRejected: 0, requestsIgnored: 0,
            requestsCompleted: 0, rating: 0, ratingFilter: 0, reviewsTotalScore: 0, reviewsCount: 0 }
            dispatch(createTowerProfile(postData,sub, accessToken.current))
        } catch (error) {
           console.log(error);
        }   
    }

    const isBtnDisabled = () => {
        return formErrors.email || formErrors.fullName || !fullName || !formEmail || !vehicleRegistration
    }

    const refetch = () => {
        dispatch(fetchTowers(companyEmail,sub,accessToken.current))
        dispatch(updateTowerReset())
        dispatch(createTowerReset())
        dispatch(deleteTowerReset())
    }

    return (
        <div>
            <div className="dtitle">
                <div className="dboard">
                    <GiTowTruck size={40} color='#010A58' />
                    <p className='dboard__text'>Towers</p>
                </div>
                <AiOutlineReload size={25} 
                                 color='#010A58' 
                                 cursor='pointer' 
                                 onClick={refetch} 
                />
            </div>
            <div className="dboard__btn">
                { !isAddTowerExpanded ? <Wrapper>
                                        <Button background='#010A58' 
                                                color='white' 
                                                {...getToggleProps({ onClick: () => setIsAddTowerExpanded(prev => !prev),})}
                                                disabled={loading}>
                                                <BiUserPlus size={20} color='white' />
                                                Add Tower
                                        </Button>
                </Wrapper> : <FormButton
                                                        {...getToggleProps({ onClick: () => {
                                                            setIsAddTowerExpanded(prev => !prev)
                                                            formMethods.setFormData({...formData, fullName: '', email: ''})
                                                            setVehicleRegistration('')
                                                        }})}
                                                    >Cancel</FormButton>}
            </div>
            <form  className='signup-form dboard__form' {...getCollapseProps()} >
                <div className='form__fields' >
                    <InputFieldReq label='Full Name' 
                                   value={fullName} 
                                   name='fullName' 
                                   placeholder='John Doe' 
                                   onChange={formMethods.handleOnChange} 
                    />
                    {<p className="form__error">{formErrors['fullName']}</p>}
                    <InputFieldReq label='Email' 
                                   value={formEmail} 
                                   name='email' 
                                   placeholder='john@doe.com' 
                                   onChange={formMethods.handleOnChange} 
                    />
                    {<p className="form__error">{formErrors['email']}</p>} 
                    <InputFieldReq label='Vehicle Reg' 
                                   value={vehicleRegistration} 
                                   placeholder='Number Plate' 
                                   onChange={onVehicleRegChange} 
                    />   
                </div>  
                <div className='form__btns' >
                    <FormButton     background='green' 
                                    color='white' 
                                    disabled={isBtnDisabled()} 
                                    onClick={handleSubmit}>Add Tower</FormButton>
                </div>
                <div className="dboard__progress-container">
                    { creating ? <Uploading text="creating tower profile" color={'black'}/> :  
                      createTowerProfileError ? <p className='error'>{createTowerProfileError}</p> : 
                      created ? <TowerUpdateSuccess text='Created successfully' /> : null }
                </div>
            </form>
            <div className='bookings__container dboard__container' >
                { loading ? <Loading loading={loading} /> : towersError ? <Error error={towersError} /> : 
                    !towers.length ? <NoItems text='No Drivers registered...yet' /> : towers.map((tower,idx)=> {
                            return <Tower key={idx} tower={tower} />
                })}
            </div>
        </div>
    )
}

export default Towers