import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
 
import {FaLanguage} from 'react-icons/fa'
import {GoLocation} from 'react-icons/go'
import {FiTwitter} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {FiFacebook} from 'react-icons/fi'

const Subheading = styled.p`
        margin: 5px 10px 5px;
`

const Heading = styled.p`
    margin: 20px 0 10px
`

const Footer = () => {
    const history = useHistory()

  return (
    <section className="footer">
        <div className="about div">
            <h2 className='footer__h2'>Tap A Service</h2>
            <p className='footer__p_it'>About us</p>
            <p className='footer__p_it' onClick={() => history.push('/terms-of-use')}>Terms and Conditions</p>
            <p className='footer__p_it' onClick={() => history.push('/privacy-policy')}>Privacy Policy</p>
       </div>
       <div className="socials div">
            <p className='footer__p_it'>Services</p>
            <p className='footer__p_i'>Online Vehicle Maintenance</p>
            <p className='footer__p_i'>Roadside Assistance</p>
        </div>
        <div className="socials div">
            <p className='footer__p_it'>Address</p>
            <p className='footer__p_i'>11 Marco Polo St</p>
            <p className='footer__p_i'>Highveld</p>
            <p className='footer__p_i'>Centurion</p>
            <p className='footer__p_i'>0157</p>
        </div>
        <div className="socials div">
            <p className='footer__p_it'>Contact Us</p>
            <p className='footer__p_i'>info@tapaservice.co.za</p>
            <p className='footer__p_i'>+27634790289</p>
        </div>
       {/* <div className='language div'>
           <p className='footer__p'><FaLanguage color='white' /><span className='footer__p'>English</span></p>
            <p className='footer__p'><GoLocation color='white'/><span className='footer__p'>Johannesburg</span></p>
       </div> */}
        <div className="socials div">
            <p className='footer__p_it'>Follow us</p>
            <div className="socials__icons">
                <FiFacebook color='white' />
                <FiInstagram color='white'/>
                <FiTwitter color='white'/>
            </div>
        </div>
        <div className="copy-right div">
            <p className='footer__p'>&copy;2024 Tap A Service</p>
        </div>
    </section>
  )
}

export default Footer
